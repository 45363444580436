exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gel-access-code-js": () => import("./../../../src/pages/gel/[access_code].js" /* webpackChunkName: "component---src-pages-gel-access-code-js" */),
  "component---src-pages-gift-program-access-code-js": () => import("./../../../src/pages/gift-program/[access_code].js" /* webpackChunkName: "component---src-pages-gift-program-access-code-js" */),
  "component---src-pages-gift-program-js": () => import("./../../../src/pages/gift-program.js" /* webpackChunkName: "component---src-pages-gift-program-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-routine-js": () => import("./../../../src/pages/routine.js" /* webpackChunkName: "component---src-pages-routine-js" */),
  "component---src-pages-thank-you-access-code-js": () => import("./../../../src/pages/thank-you/[access_code].js" /* webpackChunkName: "component---src-pages-thank-you-access-code-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

